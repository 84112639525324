.grecaptcha-badge {
  visibility: hidden;
}

.trustpilot-widget iframe {
  max-height: 86px;
}

@media (min-width: 30em) {
  .trustpilot-widget iframe {
    max-height: 24px;
  }
}
